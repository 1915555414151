.terminal {
  background-color: #1e1e1e;
  color: #f0f0f0;
  font-family: 'Fira Code', 'Courier New', monospace;
  padding: 30px;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  line-height: 1.6;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  max-width: 100%;
  overflow-x: hidden;
}

.version-tag {
  position: fixed;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  z-index: 1000;
}

.terminal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap;
  position: relative;
  z-index: 10;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 15px;
}

.terminal-buttons {
  display: flex;
}

.terminal-button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
  transition: transform 0.2s ease;
  position: relative;
}

.contact-button {
  background-color: #61afef;
  color: #1e1e1e;
  padding: 5px 10px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 12px;
  transition: background-color 0.2s ease;
  margin-left: auto;
}

.contact-button:hover {
  background-color: #528bbd;
}

.social-icons {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.social-icon {
  color: #f0f0f0;
  font-size: 18px;
  margin-left: 10px;
  transition: color 0.2s ease;
}

.social-icon:hover {
  color: #61afef;
}

.terminal-button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
  transition: transform 0.2s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  color: transparent;
  overflow: hidden;
  border: none;
}

.terminal-button:hover {
  transform: scale(1.2);
}

.terminal-button::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s ease;
  pointer-events: none;
  background: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
}

.terminal-button:hover::before {
  opacity: 1;
}

.close { background-color: #ffff00; }
.close:hover::before { content: '×'; color: #000000; }

.minimize { background-color: #ffff00; }
.minimize:hover::before { content: '−'; color: #000000; }

.maximize { background-color: #00ff00; }
.maximize:hover::before { content: '□'; color: #000000; }

.terminal-button {
  pointer-events: auto;
}

.input-line {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 100%;
  position: relative;
  z-index: 5;
}


.terminal-output {
  flex-grow: 1;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  max-width: 100%;
  overflow-x: hidden;
  padding-right: 10px;
  margin-bottom: 10px;
}

.terminal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #1e1e1e;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.terminal-footer .input-line {
  flex-grow: 1;
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.terminal-footer .version-tag {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.terminal-content {
  margin-bottom: 50px; /* Add space for the fixed footer */
}

.input, .output {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.prompt {
  color: #61afef;
  margin-right: 10px;
}

input {
  background-color: transparent;
  border: none;
  color: #f0f0f0;
  font-family: inherit;
  font-size: inherit;
  outline: none;
  flex-grow: 1;
  min-width: 0;
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.file-content {
  font-size: 14px;
  line-height: 1.6;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  max-width: 100%;
  overflow-x: hidden;
}

.file-content pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
  overflow-x: hidden;
}

.welcome-message {
  color: #98c379;
  margin-bottom: 20px;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.cursor {
  display: inline-block;
  width: 8px;
  height: 14px;
  background-color: #f0f0f0;
  margin-left: 2px;
  animation: blink 1s step-end infinite;
}

.terminal.mini-mode {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  height: 200px;
  z-index: 1000;
  border-radius: 5px;
  overflow: hidden;
  background-color: #1e1e1e;
}

.terminal::-webkit-scrollbar {
  width: 10px;
}

.terminal::-webkit-scrollbar-track {
  background: #1e1e1e;
}

.terminal::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.terminal::-webkit-scrollbar-thumb:hover {
  background: #555;
}


@media only screen and (max-width: 480px) {
  .terminal {
    padding: 10px;
    font-size: 13px;
  }

  .terminal-button {
    width: 14px;
    height: 14px;
    margin-right: 8px;
  }

  .file-content {
    font-size: 12px;
  }

  .terminal.mini-mode {
    height: 250px;
  }

  .input-line {
    flex-direction: row;
    align-items: center;
  }

  .input-line .terminal-button.ai {
    margin-bottom: 0;
    margin-right: 8px;
  }

  .prompt {
    font-size: 13px;
  }

  .terminal-output {
    max-height: calc(100vh - 130px);
  }
}

@media only screen and (max-width: 768px) {
  .terminal-header {
    position: sticky;
    top: 0;
    background-color: #1e1e1e;
    z-index: 100;
    padding-top: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  .terminal-buttons {
    margin-bottom: 10px;
  }

  .contact-button {
    align-self: flex-start;
    margin-top: 10px;
  }

  .terminal-output {
    overflow-x: auto;
  }

  .input-line, .output {
    max-width: 100%;
    overflow-x: auto;
  }
}
