/* Base styles for the terminal */
.terminal {
  background-color: #1e1e1e;
  color: #f0f0f0;
  font-family: 'Fira Code', 'Courier New', monospace;
  padding: 20px;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  line-height: 1.6;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
}

/* Terminal header and buttons */
.terminal-header {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.terminal-button {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
  transition: transform 0.2s ease;
  position: relative;
}

.terminal-button:hover {
  transform: scale(1.2);
}

.terminal-button::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s ease;
  pointer-events: none;
}

.terminal-button:hover::after {
  opacity: 1;
}

.close { background-color: #ff5f56; }
.minimize { background-color: #ffbd2e; }
.maximize { background-color: #27c93f; }

/* Terminal output area */
.terminal-output {
  flex-grow: 1;
  margin-bottom: 20px;
  font-size: 16px;
  overflow-y: auto;
  max-height: calc(100vh - 100px); /* Adjust based on your terminal header and input area height */
}

.input, .output {
  margin-bottom: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.prompt {
  color: #61afef;
  margin-right: 10px;
}

.input-line {
  display: flex;
  align-items: center;
}

input {
  background-color: transparent;
  border: none;
  color: #f0f0f0;
  font-family: inherit;
  font-size: inherit;
  outline: none;
  flex-grow: 1;
}

/* Welcome message */
.welcome-message {
  color: #98c379;
  margin-bottom: 20px;
  font-size: 18px;
}

/* ASCII art */
.ascii-art {
  font-size: 6px;
  line-height: 1.2;
  font-family: monospace;
  white-space: pre;
  color: #98c379;
  margin-bottom: 20px;
}

/* File content */
.file-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 16px;
  line-height: 1.6;
}

.file-content p {
  margin-bottom: 15px;
}

.file-content h1 { font-size: 2em; margin-top: 25px; margin-bottom: 15px; }
.file-content h2 { font-size: 1.7em; margin-top: 20px; margin-bottom: 12px; }
.file-content h3 { font-size: 1.4em; margin-top: 18px; margin-bottom: 10px; }

.file-content ul, .file-content ol {
  margin-left: 25px;
  margin-bottom: 15px;
}

.file-content li {
  margin-bottom: 8px;
}

/* Directory and file colors */
.directory {
  color: #61afef;
}

.file {
  color: #f0f0f0;
}

/* Heading styles for non-file content */
h1, h2, h3 {
  color: #61afef;
  margin-top: 20px;
  margin-bottom: 10px;
}

h1 { font-size: 1.7em; }
h2 { font-size: 1.5em; }
h3 { font-size: 1.3em; }

/* List styles for non-file content */
ul, ol {
  margin-left: 20px;
  margin-bottom: 10px;
}

/* Highlight text */
.highlight {
  color: #98c379;
  font-weight: bold;
}

/* Blinking cursor effect */
@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.cursor {
  display: inline-block;
  width: 8px;
  height: 16px;
  background-color: #f0f0f0;
  margin-left: 2px;
  animation: blink 1s step-end infinite;
}

/* Mini mode for terminal */
.terminal.mini-mode {
  height: 400px;
  width: 600px;
}

.terminal.mini-mode .terminal-content {
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 400px;
  width: 600px;
  background-color: #1e1e1e;
  border: 1px solid #61afef;
  border-radius: 5px;
  overflow: hidden;
  z-index: 1000;
}

/* Scrollbar styling */
.terminal::-webkit-scrollbar {
  width: 12px;
}

.terminal::-webkit-scrollbar-track {
  background: #1e1e1e;
}

.terminal::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.terminal::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Site name styling */
.site-name {
  position: fixed;
  bottom: 10px;
  right: 20px;
  font-size: 14px;
  color: #61afef;
  opacity: 0.7;
}

/* Media query for larger screens */
@media screen and (min-width: 1024px) {
  .terminal {
    font-size: 18px;
  }
  
  .terminal-output {
    font-size: 18px;
  }
  
  .welcome-message {
    font-size: 20px;
  }
  
  .ascii-art {
    font-size: 8px;
  }
  
  .file-content {
    font-size: 18px;
  }
  
  .file-content h1 { font-size: 2.2em; }
  .file-content h2 { font-size: 1.9em; }
  .file-content h3 { font-size: 1.6em; }
}
