/* src/components/MobileUI.css */

.mobile-ui {
  background-color: #1e1e1e;
  color: #f0f0f0;
  font-family: 'Fira Code', 'Courier New', monospace;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.mobile-header {
  background-color: #2c2c2c;
  color: #f0f0f0;
  padding: 15px;
  text-align: center;
}

.mobile-header h1 {
  margin: 0;
  font-size: 20px;
}

.mobile-header p {
  margin: 5px 0 0;
  font-size: 12px;
}

.mobile-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 15px;
}

/* Added a wrapper for content and back button */
.mobile-content-inner {
  display: flex;
  flex-direction: column;
}

.mobile-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #444;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.mobile-item:hover,
.mobile-item:active {
  background-color: rgba(65, 105, 225, 0.1);
}

.mobile-item:last-child {
  border-bottom: none;
}

.mobile-item-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 18px;
}

.mobile-item-name {
  font-size: 14px;
  flex-grow: 1;
}

/* Back Button Styling */
.mobile-back-button {
  align-self: flex-end; /* Move to the right */
  margin-top: 20px;     /* Add padding between content and button */
}

/* Ensure the back button looks like other buttons */
.mobile-button {
  background-color: #61afef;
  color: #1e1e1e;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  text-decoration: none;
  display: inline-block;
}

.mobile-button:hover {
  background-color: #528bbd;
}

a.mobile-button {
  color: #1e1e1e;
  text-decoration: none;
}

.mobile-output {
  background-color: #2c2c2c;
  padding: 15px;
  overflow-y: auto;
  max-height: 30vh;
  font-size: 12px;
  line-height: 1.4;
}

.mobile-output .input {
  color: #61afef;
}

.mobile-output .output {
  color: #98c379;
}

.mobile-file-content {
  padding: 15px;
  background-color: #2c2c2c;
}

.mobile-file-content h2 {
  color: #61afef;
  margin-bottom: 10px;
}

.mobile-file-content pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  color: #98c379;
  font-size: 14px;
  line-height: 1.6;
}

.mobile-file-content button {
  margin-top: 15px;
  background-color: #61afef;
  color: #1e1e1e;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.mobile-file-content button:hover {
  background-color: #528bbd;
}

.mobile-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #2c2c2c;
}

.mobile-social-icons {
  display: flex;
  gap: 10px;
}

.mobile-social-icon {
  color: #f0f0f0;
  font-size: 18px;
  transition: color 0.2s ease;
}

.mobile-social-icon:hover {
  color: #61afef;
}

@media (max-width: 320px) {
  .mobile-header h1 {
    font-size: 18px;
  }

  .mobile-header p {
    font-size: 10px;
  }

  .mobile-item-name {
    font-size: 12px;
  }

  .mobile-output {
    font-size: 10px;
  }

  .mobile-footer button {
    font-size: 10px;
    padding: 6px 10px;
  }
}

